import React, { lazy, Suspense, useEffect, useState } from "react";
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Spinner from "./components/Spinner";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { BASE_DOMAIN, getAPI, PANEL_TYPE, SUPER_ADMIN_URL } from "./utils/config";
import { isValidUser } from "./utils/common";
import "react-toastify/ReactToastify.css";
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const PageNotFound = lazy(() => import("./components/404"));
const PrivateRouteLayout = lazy(() => import("./layout/privateRouteLayout"));
const NotificationsLogs = lazy(() =>
  import("./pages/notifications_logs/notifications_logs")
);
const TripFeedback = lazy(() => import("./pages/trip_feedback/trip_feedback"));
const Trips = lazy(() => import("./pages/trips/Create"));
const ViewRequests = lazy(() => import("./pages/trips/View"));
const TripsList = lazy(() => import("./pages/trips/TripList"));
const TripsEdit = lazy(() => import("./pages/trips/edit"));

function App() {
  const navigate = useNavigate();
  const [info, setInfo] = useState({});
  const [isLogin, setIsLogin] = useState(true)
  let location = useLocation();

  const handleLoginError = () => {
    setIsLogin(false);
    Cookies.remove("G-accessToken", { domain: BASE_DOMAIN });
    window.location.replace(SUPER_ADMIN_URL);
  };

  useEffect(() => {
    const getDetails = async () => {
      try {
        const decodedToken = jwt_decode(Cookies.get("G-accessToken"));
        if (decodedToken?.ID) {
          const detailInfo = await getAPI({
            url: `user/detail`,
            params: { ID: decodedToken?.ID },
          });
          if (!isValidUser(detailInfo)) {
            handleLoginError();
            return;
          }
          if (detailInfo?.data?.payload?.error) {
            handleLoginError();
            return;
          }
          if (detailInfo?.data?.payload) {
            if (detailInfo?.data?.payload?.role?.UID !== PANEL_TYPE) {
              handleLoginError();
              return;
            }
            setInfo({
              ...detailInfo?.data?.payload,
              cpassword: detailInfo?.data?.payload?.password,
            });
          } else {
            handleLoginError();
            return;
          }
        }
      } catch (error) {
        console.log('*********************************************');
        console.log('error');
        console.log(error);
        console.log('============================================');
        handleLoginError();
        return;
      }


    };
    getDetails();

  }, [navigate]);

  // useEffect(() => {
  //   if (!Cookies.get("G-accessToken")) {
  //     window.location.replace(SUPER_ADMIN_URL);
  //     return;
  //   }
  //   const getDetails = async () => {
  //     try {
  //       const decodedToken = jwt_decode(Cookies.get("G-accessToken"));
  //       if (decodedToken?.ID) {
  //         const detailInfo = await getAPI({
  //           url: `user/detail`,
  //           params: { ID: decodedToken?.ID },
  //         });
  //         if (!isValidUser(detailInfo)) {
  //           // window.location.href = SUPER_ADMIN_URL
  //         }
  //         if (detailInfo?.data?.payload) {
  //           if (detailInfo?.data?.payload?.role?.UID !== PANEL_TYPE) {
  //             // window.location.href = SUPER_ADMIN_URL
  //           }
  //           setInfo({
  //             ...detailInfo?.data?.payload,
  //             cpassword: detailInfo?.data?.payload?.password,
  //           });
  //         } else {
  //         }
  //       }
  //     } catch (error) {
  //       /* window.location.replace(SUPER_ADMIN_URL) */
  //       // return
  //     }
  //   };
  //   getDetails();
  // }, [navigate]);
  return (
    <main>
      {/* <Suspense fallback={<Spinner />}> */}
      <Suspense >
        <div className="bg-skin-gray h-screen">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            theme="colored"
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes>

            {!isLogin ?
              <>
                <Route path="*" element={<PageNotFound />} />
              </>
              :
              (
                <>
                  <Route
                    element={
                      <PrivateRouteLayout userInfo={info}>
                        <Outlet />
                      </PrivateRouteLayout>
                    }
                  >
                    <Route exact path="/dashboard" element={<Dashboard userInfo={info} />} />
                    <Route
                      exact
                      path="/trips/add"
                      element={<Trips userInfo={info} />}
                    />
                    <Route
                      exact
                      path="/trips/view"
                      element={<ViewRequests userInfo={info} />}
                    />
                    <Route
                      exact
                      path="/trips/list"
                      element={<TripsList userInfo={info} />}
                    />
                    <Route
                      exact
                      path="/trips/edit"
                      element={<TripsEdit />}
                    />
                    <Route
                      exact
                      path="/notifications_logs"
                      element={<NotificationsLogs />}
                    />
                    <Route exact path="/trip_feedback" element={<TripFeedback />} />
                    <Route
                      exact
                      path="/profile/user_profile"
                      element={<Profile userInfo={info} />}
                    />
                  </Route>
                </>
              )
            }
          </Routes>
        </div>
      </Suspense>
    </main>
  );
}
export default App;
