import Cookies from "js-cookie";

export const validateForm = (formBody, requiredField) => {
  const fields = { ...formBody };
  let errors = {};
  let formIsValid = true;

  if (requiredField.includes("tripTypeID")) {
    if (!fields["tripTypeID"]) {
      formIsValid = false;
      errors["tripTypeID"] = "Por favor selecione Tipo de viagem";
    }
  }
  if (requiredField.includes("transportTypeID")) {
    if (!fields["transportTypeID"]) {
      formIsValid = false;
      errors["transportTypeID"] = "Por favor selecione Tipo de transporte";
    }
  }
  if (requiredField.includes("tripTitle")) {
    if (!fields["tripTitle"]) {
      formIsValid = false;
      errors["tripTitle"] = "Por favor, insira Descrição do objectivo da viagem";
    }
  }
  if (requiredField.includes("tripStartDate")) {
    if (!fields["tripStartDate"]) {
      formIsValid = false;
      errors["tripStartDate"] = "Por favor selecione Data da partida";
    }
  }
  if (requiredField.includes("tripReasonID")) {
    if (!fields["tripReasonID"]) {
      formIsValid = false;
      errors["tripReasonID"] = "Por favor selecione Objectivo da viagem";
    }
  }
  if (requiredField.includes("tripFrom")) {
    if (!fields["tripFrom"]) {
      formIsValid = false;
      errors["tripFrom"] = "Por favor selecione Origem";
    }
  }
  if (requiredField.includes("tripTo")) {
    if (!fields["tripTo"]) {
      formIsValid = false;
      errors["tripTo"] = "Por favor selecione Destino";
    }
  }
  if (requiredField.includes("tripEndDate")) {
    if (!fields["tripEndDate"]) {
      formIsValid = false;
      errors["tripEndDate"] = "Select Data de regresso";
    }
  }

  if (requiredField.includes("number_of_days")) {
    if (!fields["number_of_days"]) {
      formIsValid = false;
      errors["number_of_days"] = "Por favor, insira Período (dias)";
    }
  }
  if (requiredField.includes("responsible_expense")) {
    if (!fields["responsible_expense"]) {
      formIsValid = false;
      errors["responsible_expense"] = "Por favor, insira Responsável pela despesa";
    }
  }
  if (requiredField.includes("ci_file")) {
    if (typeof fields["ci_file"] === "object" || fields["ci_file"]?.length > 0) {
      formIsValid = true;
    } else {
      if (!fields["ci_file"]) {
        formIsValid = false;
        errors["ci_file"] = "Por favor, insira  Carregue a CI para pedido de autorização de saída";
      }
    }
  }

  if (requiredField.includes("dispatch_number")) {
    var re = /^.*(?=.{5,20}).*$/;
    if (!fields["dispatch_number"]) {
      formIsValid = false;
      errors["dispatch_number"] = "Por favor, insiraNº do despacho de saída ";
    }
    if (fields["dispatch_number"]) {
      if (!re.test(fields["dispatch_number"])) {
        formIsValid = false;
        errors["dispatch_number"] = "Por favor, insiraNº do despacho de saída 5 to 20 ";
      }
    }
  }
  if (requiredField.includes("newPassword")) {
    if (!fields["newPassword"]) {
      formIsValid = false;
      errors["newPassword"] = "Por favor, digite a senha";
    }
  }
  if (requiredField.includes("confirmPassword")) {
    if (!fields["confirmPassword"]) {
      formIsValid = false;
      errors["confirmPassword"] = "Por favor, digite a senha de confirmação";
    }
  }

  if (requiredField.includes("return_date")) {
    if (!fields["return_date"]) {
      formIsValid = false;
      errors["return_date"] = "Selecione a data de devolução";
    }
  }
  if (requiredField.includes("departure_date")) {
    if (!fields["departure_date"]) {
      formIsValid = false;
      errors["departure_date"] = "Por favor, selecione a data de partida";
    }
  }
  if (requiredField.includes("postponed_reason")) {
    if (!fields["postponed_reason"]) {
      formIsValid = false;
      errors["postponed_reason"] = "Insira o motivo do adiamento";
    }
  }


  if (requiredField.includes("avtar")) {
    if (typeof fields["avtar"] === "object" || fields["avtar"]?.length > 0) {
      formIsValid = true;
    } else {
      if (!fields["avtar"]) {
        formIsValid = false;
        errors["avtar"] = "Por favor, insira  Carregue a Relatório da Viagem de saída";
      }
    }
  }


  return {
    isValid: formIsValid,
    errors,
  };
};

export const isValidUser = (request) => {
  if (request?.response?.status === 401 || request?.response?.status === 500) {
    Cookies.remove("G-accessToken");
    return false;
  }
  return true;
};
