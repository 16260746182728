import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAPI } from '../../utils/config';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notificationsCount, setNotificationsCount] = useState(0);

    useEffect(() => {
        const storedNotificationsCount = localStorage.getItem('notificationsCount');
        const initialCount = parseInt(storedNotificationsCount, 10) || 0;
        setNotificationsCount(initialCount);
    }, []);

    /**
 * @author : Kishan Sonani.
 * @purpose : Get Notification Count
 * @param : 
 * @since : 03-Jul-2023.
 */
    const fetchNotificationsCount = async () => {
        try {
            const response = await getAPI({
                url: `notification/list?display_type=GACA`,
                params: { status: "Unread" },
            });
            if (response?.data?.payload) {
                setNotificationsCount(response?.data?.payload?.count);
            }
        } catch (error) {
            // Handle error
        }
    }

    useEffect(() => {
        fetchNotificationsCount();
    }, []);
    useEffect(() => {
        localStorage.setItem('notificationsCount', notificationsCount.toString());
    }, [notificationsCount]);

    return (
        <NotificationContext.Provider value={{ notificationsCount, setNotificationsCount }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);
