import axios from "axios";
import Cookies from "js-cookie";
const getAPIBaseURl = () => {
  return process.env.REACT_APP_BASE_API_URL;
};
export const BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN;
export const STATIC_CONTENT = process.env.REACT_APP_STATIC_PATH_URL;
export const STATIC_CONTENT_PROFILE = process.env.REACT_APP_STATIC_PATH_PROFILE_URL;
export const STATIC_CONTENT_GACA = process.env.REACT_APP_STATIC_PATH_GACA_URL;
export const PANEL_TYPE = process.env.REACT_APP_PANEL_TYPE;
export const SUPER_ADMIN_URL = process.env.REACT_APP_SUPER_ADMIN_LINK;

export const TICKET_SERVICE_TYPE_ID = process.env.REACT_APP_TICKET_SERVICE_TYPE_ID;
export const TRANSFER_SERVICE_TYPE_ID = process.env.REACT_APP_TRANSFER_SERVICE_TYPE_ID;
export const HOTEL_SERVICE_TYPE_ID = process.env.REACT_APP_HOTEL_SERVICE_TYPE_ID;
export const VISTOS_SERVICE_TYPE_ID = process.env.REACT_APP_VISTOS_SERVICE_TYPE_ID;
export const RENT_CAR_SERVICE_TYPE_ID = process.env.REACT_APP_RENT_CAR_SERVICE_TYPE_ID;

export const NATIONAL_TRIP_TYPE_ID = process.env.REACT_APP_NATIONAL_TRIP_TYPE_ID;
export const CI_FILE = process.env.REACT_APP_STATIC_PATH_CI_FILE_URL;

const getReqHeaders = () => {
  const accessToken = Cookies.get("G-accessToken");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export const postAPI = async (data) => {
  try {
    return await axios.post(`${getAPIBaseURl()}${data.url}`, data.params, {
      headers: getReqHeaders(),
    });
  } catch (error) {
    return error;
  }
};

export const deleteAPI = async (data) => {
  try {
    return await axios.delete(`${getAPIBaseURl()}${data.url}`, {
      headers: getReqHeaders(),
      data: data.params,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      Cookies.remove("G-accessToken", { domain: BASE_DOMAIN });
    }
    return error;
  }
};

export const patchAPI = async (data) => {
  try {
    return await axios.patch(`${getAPIBaseURl()}${data.url}`, data.params, {
      headers: getReqHeaders(),
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      Cookies.remove("G-accessToken", { domain: BASE_DOMAIN });
    }
    return error;
  }
};

export const getAPI = async (data) => {
  try {
    return await axios.get(`${getAPIBaseURl()}${data.url}`, {
      params: data.params,
      headers: getReqHeaders(),
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      Cookies.remove("G-accessToken", { domain: BASE_DOMAIN });
    }
    return error;
  }
};

export const putAPI = async (data) => {
  try {
    return await axios.put(`${getAPIBaseURl()}${data.url}`, data.params, {
      headers: getReqHeaders(),
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      Cookies.remove("G-accessToken", { domain: BASE_DOMAIN });
    }
    return error;
  }
};
